import React from 'react';
import PropTypes from 'prop-types';
import { utcToZonedTime, format } from 'date-fns-tz';
import cn from 'classnames';

import IconSvg from '../../../../../../common/javascripts/components/IconSvg';

import styles from './Time.module.scss';

export default function EventTime({ startAt, endAt, className }) {
  if (!startAt || !endAt) return null;

  const zonedStartAt = utcToZonedTime(startAt, 'Europe/Moscow');
  const zonedEndAt = utcToZonedTime(endAt, 'Europe/Moscow');

  return (
    <div className={cn(styles.Time, className)}>
      <IconSvg icon="timer" />
      {`${format(zonedStartAt, 'HH:mm')} – ${format(zonedEndAt, 'HH:mm')}`}
    </div>
  );
}

EventTime.propTypes = {
  startAt: PropTypes.string.isRequired,
  endAt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

EventTime.defaultProps = {
  className: '',
};
