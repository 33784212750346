import React from 'react';
import PropTypes from 'prop-types';
// import Logo from '../../../../../../images/rf-logo-events.png';
import SbpMono from '../../../../../../images/sbp_logo_rgb-08.png';
import styles from './EventsHeader.module.scss';

function EventPlayers({ imageUrl }) {
  return (
    <div className={styles.EventsHeader}>
      {/* <h1 className={styles.EventsHeader__header}>Программа</h1> */}
      <div className={styles.EventsHeader__logo}>
        <img src={imageUrl || SbpMono} alt="sbp" />
      </div>
    </div>
  );
}

EventPlayers.propTypes = {
  imageUrl: PropTypes.string.isRequired,
};

export default React.memo(EventPlayers);
