import React from 'react';
import PropTypes from 'prop-types';

// import CardTagsList from '../../CardTagsList';
import AgeRestrictionBadge from '../../AgeRestrictionBadge';
import PartnerCoverImage from '../CoverImage';
import Card from '../../Card';

import styles from './Card.module.scss';

export default function PartnerListCard({ partner }) {
  return (
    <Card>
      <PartnerCoverImage partner={partner} />
      <div className={styles.PartnerListCard__tags}>
        {/* <CardTagsList tags={partner.tags} /> */}
        <AgeRestrictionBadge age={partner.age_restriction} />
      </div>
      <div className={styles.PartnerListCard__title}>
        {partner.name}
      </div>
    </Card>
  );
}

PartnerListCard.propTypes = {
  partner: PropTypes.shape({
    tags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      important: PropTypes.bool,
      title: PropTypes.string,
    })),
    bonus_for_member_html: PropTypes.string,
    age_restriction: PropTypes.number,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
