/* eslint-disable react/prop-types */
/* TODO: proptypes validation */
import React from 'react';

import Card from '../../../../../components/Card';
import CardTagsList from '../../../../../components/CardTagsList';
import CardHeader from '../../../../../components/CardHeader';
import AgeRestrictionBadge from '../../../../../components/AgeRestrictionBadge';
import FromEditor from '../../../../../components/FromEditor';
import PartnerCoverImage from '../../../../../components/Partner/CoverImage';
import Address from '../../../../../components/Address';
import SocialButtons from '../../../../../components/SocialButtons';

import styles from './InfoCard.module.scss';

export default function PartnerInfoCard({ partner }) {
  const hasShops = (partner.shops || []).length > 0;
  const hasContacts = (partner.phone && partner.phone?.match(/\d/g)?.length === 11)
    || partner.vk_profile
    || partner.fbProfile
    || partner.ig_profile;

  const shops = hasShops && partner.shops.map((shop) => (
    <li key={shop.id} className={styles.PartnerInfoCard__shop}>
      <Address
        title={shop.name}
        address={shop.address}
        lat={shop.lat}
        lon={shop.lon}
        startAt={shop.start_time}
        endAt={shop.end_time}
      />
    </li>
  ));

  const handleClickPartnerUrl = () => {
    if (!partner.url) return;

    window.open(`https://${partner.url}`, '_blank');
  };

  return (
    <Card noShadow>
      <CardHeader bottomSpace="m">
        <h1>{partner.name}</h1>
      </CardHeader>

      <div className={styles.PartnerInfoCard__image}>
        <PartnerCoverImage partner={partner} />
      </div>

      <div className={styles.PartnerInfoCard__tags}>
        <CardTagsList tags={partner.tags} />
        <AgeRestrictionBadge age={partner.age_restriction} />
      </div>

      {partner.bonus_for_member_html && (
        <div className={styles.PartnerInfoCard__howToUseWrap}>
          <CardHeader className={styles.PartnerInfoCard__header}>
            <h2>Бонусы для участников</h2>
          </CardHeader>
          <FromEditor>{partner.bonus_for_member_html}</FromEditor>
        </div>
      )}

      <div className={styles.PartnerInfoCard__description}>
        <FromEditor>{partner.description_html}</FromEditor>
      </div>

      {
        hasShops
        && (
          <>
            <CardHeader>
              <h2>Адрес</h2>
            </CardHeader>

            <ul className={styles.PartnerInfoCard__shopsList}>
              {shops}
            </ul>
          </>
        )
      }

      {
        partner.url
        && (
          <div className={styles.PartnerInfoCard__contacts}>
            <button
              type="button"
              className="button button_large button_fullwidth"
              onClick={handleClickPartnerUrl}
            >
              открыть сайт
            </button>
          </div>
        )
      }
      {/* TODO: extract to own component */}
      {
        hasContacts
        && (
          <>
            <CardHeader bottomSpace="s">
              <h2>Контакты</h2>
            </CardHeader>

            <div className={styles.PartnerInfoCard__contacts}>
              {
                (partner.phone && partner.phone?.match(/\d/g)?.length === 11)
                && (
                  <a href={`tel:${partner.phone}`} className={styles.PartnerInfoCard__contactPhone} target="_blank" rel="noreferrer">
                    {partner.phone}
                  </a>
                )
              }
              {/*
                partner.email
                && (
                  <a href={`mailto:${partner.email}`} className={styles.PartnerInfoCard__contactLink} target="_blank" rel="noreferrer">
                    {partner.email}
                  </a>
                )
              */}
            </div>

            <SocialButtons
              vkProfile={partner.vk_profile}
              fbProfile={partner.fb_profile}
              igProfile={partner.ig_profile}
            />
          </>
        )
      }
    </Card>

  );
}
