import isNil from 'lodash/isNil';

const BASIC_USER_NAME = process.env.BASIC_AUTH_USERNAME;
const BASIC_PASSWORD = process.env.BASIC_AUTH_PASSWORD;

export const DEFAULT_HEADERS = {
  'WWW-Authenticate': 'BasicCustom',
  Authorization: 'Basic credential',
  'X-Requested-With': 'XMLHttpRequest',
};

export const DEFAULT_OPTIONS = {
  mode: 'cors', // no-cors, *cors, same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  credentials: 'same-origin', // include, *same-origin, omit
  redirect: 'follow', // manual, *follow, error
  referrerPolicy: 'no-referrer', // no-referrer, *client
  headers: {
    ...DEFAULT_HEADERS,
  },
};

// staging basic auth
if (!isNil(BASIC_USER_NAME) && !isNil(BASIC_PASSWORD)) {
  const credentials = btoa(`${BASIC_USER_NAME}:${BASIC_PASSWORD}`);

  Object.assign(DEFAULT_HEADERS, { Authorization: `Basic ${credentials}` });
}

export const headersWithToken = (contentType) => {
  const headers = new Headers();
  const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  const contentTypeLocal = contentType || 'application/json';

  headers.append('X-CSRF-Token', csrf);
  headers.append('X-Requested-With', 'XMLHttpRequest');
  headers.append('WWW-Authenticate', 'BasicCustom');
  headers.append('Content-Type', contentTypeLocal);
  return headers;
};

export const authenticityToken = () => (document.querySelector('meta[name="csrf-token"]').getAttribute('content'));
