import React, { useState } from 'react';
import PropTypes from 'prop-types';

import * as styles from './NavigationLink.module.scss';

import BottomSlideUp from '../../../../../components/BottomSlideUp';
import AuthScreen from '../../../../Auth';
import { authenticityToken } from '../../../../../../../common/javascripts/fetchOptions';
import { expires60min, setCookie } from '../../../../../helpers/cookie';

export default function NavigationLink({
  title, url, settings,
}) {
  const [showSlideUp, setShowSlideUp] = useState(false);

  const handleLinkClick = (e) => {
    e.preventDefault();
    setShowSlideUp(true);
    setCookie('return_to', url, { expires: expires60min() });
  };

  const slideUpClose = () => setShowSlideUp(false);

  return (
    <>
      <span className={styles.NavigationLink} onClick={handleLinkClick}>
        <span className="navTabs__itemText">{title}</span>
      </span>

      <BottomSlideUp show={showSlideUp} onClose={slideUpClose}>
        <AuthScreen
          askBirthday={(settings.age_restriction || 0) > 0}
          token={authenticityToken()}
          url="/"
          welcomeText={settings}
          defaultStep={1}
          viewHeader={false}
        />
      </BottomSlideUp>
    </>
  );
}

NavigationLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    age_restriction: PropTypes.number,
    admin_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_header_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_text_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_color_quest: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_quest: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_header_color_quest: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_color_nav: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_player_menu: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_virtual_card: PropTypes.shape({
      hex: PropTypes.string,
    }),
  }).isRequired,
};
