import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import * as styles from './YandexButton.module.scss';
import IconSvg from 'common/javascripts/components/IconSvg';

const YandexButton = ({ className, onClick }) => (
  <div
    className={
      cn(
        styles.YandexButton,
        { [className]: className },
      )
    }
  >
    <button
      type="button"
      className={cn('button button_large button_fullwidth', styles.YandexButton)}
      onClick={() => onClick()}
    >
      {/* <IconSvg icon="yandex_logo" className={styles.YandexButton__ya_logo} /> */}
      <div className={styles.YandexButton__ya_logo} />
      <div className={styles.YandexButton__info}>Войти с Яндекс</div>
      <IconSvg icon="SW" className={styles.YandexButton__avatar} />
    </button>
  </div>
);

YandexButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

YandexButton.defaultProps = {
  className: '',
};

export default YandexButton;
