import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Card from '../../../../../components/Card';
import CardHeader from '../../../../../components/CardHeader';

import styles from './ModeratedAnswer.module.scss';

function ModeratedAnswer({
  updatedAnswer,
  actionsDisabled,
  onAccept,
  onReject,
  stepNumber,
  taskName,
}) {
  const isDisabledAcceptBtn = updatedAnswer.state !== 'failed' && updatedAnswer.state;
  const isDisabledRejectBtn = updatedAnswer.state !== 'completed' && updatedAnswer.state;

  const handleAcceptClick = () => {
    onAccept();
  };

  const handleRejectClick = () => {
    onReject();
  };

  return (
    <Card className={cn(styles.ModeratedAnswer, styles[`ModeratedAnswer_${updatedAnswer.state}`])}>
      <CardHeader bottomSpace="s">
        <h4>{`${taskName} – Шаг ${stepNumber}`}</h4>
      </CardHeader>
      <div className={styles.ModeratedAnswer__actions}>
        <button
          type="button"
          className="button button_fullwidth"
          disabled={actionsDisabled || isDisabledAcceptBtn}
          onClick={handleAcceptClick}
        >
          Принять
        </button>
        <button
          type="button"
          disabled={actionsDisabled || isDisabledRejectBtn}
          className="button button_fullwidth button_secondary"
          onClick={handleRejectClick}
        >
          Провалить
        </button>
      </div>

    </Card>
  );
}

ModeratedAnswer.propTypes = {
  updatedAnswer: PropTypes.shape({
    id: PropTypes.number,
    state: PropTypes.string,
  }),
  actionsDisabled: PropTypes.bool.isRequired,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  stepNumber: PropTypes.number.isRequired,
  taskName: PropTypes.string,
};

ModeratedAnswer.defaultProps = {
  updatedAnswer: {},
  taskName: 'Модерирование',
};

export default ModeratedAnswer;
