import React from 'react';
import PropTypes from 'prop-types';
import EventCard from './components/EventCard';
// import { visitOrRedirectTo } from '../../../../../common/javascripts/helpers';

import PageContentOtherBg from '../../../components/PageContentOtherBg';

export default function EventShowScreen({
  event, token, player, day, settings,
}) {
  // FREAK: не нашел другого способа вернуться,
  // так как навигация через pushState в Events
  // useEffect(() => {
  //   window.onpopstate = () => {
  //     const queryParams = new URLSearchParams(window.location.search);
  //     const eventKey = (window.history.state || {}).scrollToElement;
  //     const url = window.location.href.split('?')[0];
  //     queryParams.set('event', eventKey);
  //     visitOrRedirectTo(`${url}?${queryParams}`);
  //   };
  // });

  return (
    <PageContentOtherBg>
      <EventCard
        initialEvent={event}
        token={token}
        player={player}
        day={day}
        settings={settings}
      />
    </PageContentOtherBg>
  );
}

EventShowScreen.propTypes = {
  event: PropTypes.object,
  token: PropTypes.string,
  player: PropTypes.object,
  day: PropTypes.string.isRequired,
  settings: PropTypes.shape({
    admin_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_header_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_text_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_color_quest: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_quest: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_header_color_quest: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_color_nav: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_player_menu: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_virtual_card: PropTypes.shape({
      hex: PropTypes.string,
    }),
  }).isRequired,
};
