import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import PageCardsList from '../../../../../components/PageCardsList';

import { isPromoterOneTask, isTaskModerator } from '../../../../../helpers/players';
import ModeratedAnswer from '../ModeratedAnswer';
import moderatedAnswersStore from '../../../../../stores/moderatedAnswers';
import moderatedPlayerStore from '../../../../../stores/moderatedPlayer';

function ModeratedSteps({
  task,
  player,
}) {
  const {
    fetchAnswers,
    answers,
    acceptAnswer,
    rejectAnswer,
    isAnswerUpdating,
  } = moderatedAnswersStore;

  const moderatedPlayer = moderatedPlayerStore.player;

  const handleAcceptAnswer = (taskStepId) => {
    acceptAnswer(taskStepId);
  };

  const handleRejectAnswer = (taskStepId) => {
    rejectAnswer(taskStepId);
  };

  const moderatedSteps = task.task_steps.map((step, index) => (
    <>
      {((isPromoterOneTask(player, task.id) || isTaskModerator(player)) && moderatedPlayer) && (
        <li key={`moderatedStep${step.id}`}>
          <ModeratedAnswer
            updatedAnswer={answers.find((a) => a.task_step_id === step.id) || {}}
            actionsDisabled={isAnswerUpdating}
            onAccept={() => handleAcceptAnswer(step.id)}
            onReject={() => handleRejectAnswer(step.id)}
            stepNumber={index + 1}
            taskName={task.title}
          />
        </li>
      )}
    </>
  ));

  if (isPromoterOneTask(player, task.id) || isTaskModerator(player)) {
    useEffect(() => {
      fetchAnswers(task.id);
    }, [moderatedPlayer]);
  }

  return (
    <PageCardsList space={2}>
      {moderatedSteps}
    </PageCardsList>
  );
}

ModeratedSteps.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    task_steps: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      state: PropTypes.string,
    })),
    title: PropTypes.string,
  }).isRequired,
  player: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default observer(ModeratedSteps);
