import React, { useState } from 'react';
import PropTypes from 'prop-types';

import RankProgress from '../../../RankProgress';
import TopbarMenuAvatar from '../MenuAvatar';
import TopbarMenuName from '../MenuName';
import TopbarMenuDivider from '../MenuDivider';
import TextCopy from '../../../TextCopy';

import styles from './PlayerMenu.module.scss';
import ProgressRing from '../../../ProgressRing';
import TbankButton from '../../../../screens/Auth/components/Buttons/TbankButton';
import BottomSlideUp from '../../../BottomSlideUp/BottomSlideUp';
import TbankReAuth from '../../../TbankReAuth/TbankReAuth';

function TopbarPlayerMenu({ player, playerAPI }) {
  const id4Chars = String(player.id).padStart(4, '0');
  const [showSlideUp, setShowSlideUp] = useState(false);

  const handleClickTbutton = () => {
    setShowSlideUp(true);
  };

  return (
    <div className={styles.Menu}>
      <TopbarMenuAvatar
        avatarUrl={player.avatar_url}
        avatarType="player"
        linkUrl={playerAPI.editProfilePath(player.id)}
      />

      <TopbarMenuName
        name={player.public_full_name}
        linkUrl={playerAPI.editProfilePath(player.id)}
        fullName={player.public_full_name}
        player={player}
        linkEditPassword={playerAPI.editPassword(player.id)}
      />

      {/* <div className={styles.Menu__currentRank}>
        {player.rank_data.currentRankTitle}
      </div> */}

      <div className={styles.Menu__rankProgressWrap}>
        <RankProgress
          steps={player.rank_data.nextRankSteps}
          stepsDone={player.rank_data.stepsDone}
          rankTitle={player.rank_data.currentRankTitle}
          nextRankTitle={player.rank_data.nextRankTitle}
          allRanks={player.rank_data.allRanks}
          currentRankPosition={player.rank_data.currentRankPosition}
        />
      </div>

      <div className={styles.Menu__qrAndIdRow}>
        <TbankButton
          lable="Обновить данные с"
          large={false}
          classNameButton={styles.Menu__tbankButton}
          classNameLogo={styles.Menu__tbankIcon}
          onClick={handleClickTbutton}
        />
        <div>
          <strong>{`Мой id: ${id4Chars}` }</strong>
          <TextCopy
            className={styles.Menu__copyIdLink}
            textToCopy={id4Chars}
            label="скопировать"
            labelAfterCopy="скопировано"
          />
        </div>
      </div>

      <TopbarMenuDivider />

      <div className={styles.TaskProgress}>
        {/* <div className={styles.TaskProgress__header}>Выполненые задания</div> */}
        <div className={styles.TaskProgress__container}>
          {
            player.task_progress.map(
              ({
                id,
                title,
                total_tasks: totalTasks,
                completed_tasks: completedTasks,
              }) => {
                const rings = [
                  {
                    r: 15,
                    strokeWidth: 3,
                    stroke: '#363636',
                    percent: 100,
                  },
                  {
                    r: 15,
                    strokeWidth: 3,
                    stroke: '#ffffff',
                    percent: (completedTasks / totalTasks) * 100,
                  },
                ];
                return (
                  <div key={id}>
                    <div className={styles.ProgressRing}>
                      <ProgressRing
                        rings={rings}
                      />
                      <div className={styles.ProgressRing__text}>
                        {`${completedTasks}/${totalTasks}`}
                      </div>
                    </div>
                    {/* <a className={styles.TaskProgress__title} href={`/ratings/tag/${id}`}>
                      #
                      {title}
                    </a> */}
                    <div className={styles.TaskProgress__title}>
                      #
                      {title}
                    </div>
                  </div>
                );
              },
            )
          }
        </div>
      </div>

      <a href="/ratings/top_awards" className={styles.Menu__awardsLink}>Посмотреть Топ призы</a>

      <BottomSlideUp show={showSlideUp} onClose={() => setShowSlideUp(false)}>
        <TbankReAuth onClose={() => setShowSlideUp(false)} />
      </BottomSlideUp>

    </div>
  );
}

TopbarPlayerMenu.propTypes = {
  player: PropTypes.shape({
    id: PropTypes.number,
    avatar_url: PropTypes.string,
    public_full_name: PropTypes.string,
    name: PropTypes.string,
    surname: PropTypes.string,
    nickname: PropTypes.string,
    phone: PropTypes.string,
    rank_data: PropTypes.shape({
      nextRankSteps: PropTypes.number,
      stepsDone: PropTypes.number,
      currentRankTitle: PropTypes.string,
      nextRankTitle: PropTypes.string,
      allRanks: PropTypes.number,
      currentRankPosition: PropTypes.number,
    }),
    task_progress: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      completed_tasks: PropTypes.number,
      total_tasks: PropTypes.number,
    })),
  }).isRequired,
  playerAPI: PropTypes.shape({
    editProfilePath: PropTypes.func,
    editPassword: PropTypes.func,
  }).isRequired,
};

export default TopbarPlayerMenu;
