import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './TagsFilterButton.module.scss';

export default function TagsFilterButton({ label, onClick, active, className }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(styles.Button, { [styles.Button_active]: active, [className]: className })}
    >
      {label}
    </button>
  );
}

TagsFilterButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  className: PropTypes.string,
};

TagsFilterButton.defaultProps = {
  onClick: () => {},
  active: false,
  label: '',
  className: '',
};
