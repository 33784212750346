import {
  makeObservable,
  observable,
  action,
  runInAction,
} from 'mobx';

import NotificationApi from './notificationsApi';
import playerViewStore from './playerViews';

class NotificationStore {
  notifications = []

  currentPage = 1

  filter = 'all'

  isLoading = false

  hasMoreNotifications = false

  isMarkingAll = false

  constructor(notificationApi, viewStore) {
    makeObservable(this, {
      notifications: observable,
      currentPage: observable,
      filter: observable,
      isLoading: observable,
      isMarkingAll: observable,
      hasMoreNotifications: observable,

      fetchNotifications: action,
      fetchMoreNotifications: action,
      markAsViewed: action,
      markAllAsViewed: action,
    });
    this.notificationApi = notificationApi;
    this.playerViewStore = viewStore;
  }

  fetchNotifications = async (filter) => {
    this.notifications = [];
    this.isLoading = true;

    try {
      const data = await this.notificationApi.fetchNotifications(1, filter);

      runInAction(() => {
        this.isLoading = false;
        this.notifications = data.notifications;
        this.currentPage = 1;
        this.filter = filter;
        this.hasMoreNotifications = !!(data.meta || {}).next_page;
      });
    } catch (error) {
      console.error(error);
    }
  };

  fetchMoreNotifications = async () => {
    this.currentPage += 1;
    this.isLoading = true;

    try {
      const data = await this.notificationApi.fetchNotifications(this.currentPage, this.filter);

      runInAction(() => {
        this.isLoading = false;
        this.notifications = [...this.notifications, ...data.notifications];
        this.hasMoreNotifications = !!(data.meta || {}).next_page;
      });
    } catch (error) {
      console.error(error);
    }
  };

  markAsViewed = (notificationId) => {
    this.playerViewStore.markNotificationAsViewed(notificationId);

    const notificationIndex = this.notifications.findIndex((n) => n.id === notificationId);

    if (notificationIndex >= 0) {
      this.notifications = [
        ...this.notifications.slice(0, notificationIndex),
        { ...this.notifications[notificationIndex], viewed: true },
        ...this.notifications.slice(notificationIndex + 1, this.notifications.length),
      ];
    }
  }

  markAllAsViewed = async () => {
    this.isMarkingAll = true;
    await this.playerViewStore.markAllNotificationsAsViewed();

    runInAction(() => {
      this.notifications = this.notifications
        .map((notification) => ({ ...notification, viewed: true }));
      this.isMarkingAll = false;
    });
  }

  addNotification = (notification) => {
    // console.log(notification);
    runInAction(() => {
      this.notifications = [notification, ...this.notifications];
    });
  }
}

const store = new NotificationStore(new NotificationApi(), playerViewStore);

export default store;
