import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../../../../components/PageHeader';

import styles from './StepHeader.module.scss';

const headers = {
  start: 'Набирай очки вместе с командой и сорви денежный куш от Т-Банка',
  new: 'Создай свою команду и пригласи 3\xA0друзей',
  search: 'Присоединиться к существующей команде',
  join: 'Присоединиться к существующей команде',
};

export default function TeamPlayStepHeader({ step }) {
  return (
    <div className={styles.Header}>
      <PageHeader>
        <h1>{headers[step]}</h1>
      </PageHeader>
    </div>
  );
}

TeamPlayStepHeader.propTypes = {
  step: PropTypes.string,
};

TeamPlayStepHeader.defaultProps = {
  step: 'start',
};
