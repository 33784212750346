import { headersWithToken } from '../../../common/javascripts/fetchOptions';

const PROGRAM_MEMBER_URL = {
  spotway: 'https://spotway.ru/api/v2/braclets',
  // spotway: 'http://localhost:5001/api/v2/braclets',
  rosafest: '/program_members',
};

class ProgramMemberApi {
  searchOnRosafest = async (phone) => {
    const response = await fetch(`${PROGRAM_MEMBER_URL.rosafest}?phone=${phone}`, {
      method: 'GET',
      headers: headersWithToken(),
    });

    if (response.status === 200) return response.json();
    if (response.status === 404) return null;

    return null;
  }

  searchOnSpotway = async (phone) => {
    const response = await fetch(`${PROGRAM_MEMBER_URL.spotway}?phone=${phone}`, {
      method: 'GET',
      headers: headersWithToken(),
    });

    if (response.status === 200) return response.json();
    if (response.status === 404) return null;

    return null;
  }

  searchOnSpotwayByScan = async (link) => {
    console.log(link);

    // const v_link = 'order_id=12545&pnr=250125RU12545&recipient[id]=5563&recipient[name]=МАКСИМ&recipient[surname]=ПЕТУХОВ&recipient[phone]=+7(964)383-9746&member_ids=22779,22780,22781,22782,22783,22784';
    // const v_link = 'order_id=12545&pnr=250125RU12545&recipient[id]=12103&recipient[name]=МАКАР&recipient[surname]=БУДАРИН&recipient[phone]=+7(963)341-6400&member_ids=22779,22780,22781,22782,22783,22784';

    const response = await fetch(`${PROGRAM_MEMBER_URL.spotway}?${link}`, {
      method: 'GET',
      headers: headersWithToken(),
    });

    if (response.status === 200) return response.json();
    if (response.status === 404) return null;

    return null;
  }

  takeBracletOnSpotway = async (moderator, recipient, members) => {
    const data = {
      moderator: { name: moderator.public_full_name, phone: moderator.phone },
      recipient: { id: recipient.member_id, name: recipient.member_fio, phone: recipient.member_phone },
      member_ids: members.map((member) => member.member_id),
    };

    const response = await fetch(`${PROGRAM_MEMBER_URL.spotway}`, {
      method: 'POST',
      headers: headersWithToken(),
      body: JSON.stringify(data),
    });

    if (response.status === 200) return response.json();
    if (response.status === 404) return null;

    return null;
  }

  takeBracletOnRosafest = async (moderator, recipient, members) => {
    const data = {
      moderator: { name: moderator.public_full_name, phone: moderator.phone },
      recipient: { id: recipient.member_id, name: recipient.member_fio, phone: recipient.member_phone },
      members: members,
    };

    const response = await fetch(`${PROGRAM_MEMBER_URL.rosafest}`, {
      method: 'POST',
      headers: headersWithToken(),
      body: JSON.stringify(data),
    });

    if (response.status === 200) return response.json();
    if (response.status === 404) return null;

    return null;
  }

  addProgramMember = async (moderator, phone) => {
    const data = {
      moderator: { name: moderator.public_full_name, phone: moderator.phone },
      recipient: { id: -2, name: '', phone: phone },
      members: [{
        member_phone: phone,
        member_fio: '',
        pnr: '',
        type_adding: 3,
      }],
    };

    const response = await fetch(`${PROGRAM_MEMBER_URL.rosafest}`, {
      method: 'POST',
      headers: headersWithToken(),
      body: JSON.stringify(data),
    });

    if (response.status === 200) return response.json();
    if (response.status === 404) return null;

    return null;
  }
}

export default ProgramMemberApi;
