import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconSvg from '../../../../../../../common/javascripts/components/IconSvg';

import './Dropdown.scss';
import styles from './CartItem.module.scss';

export default function PruductOrderCartItem({
  item, onItemChange, variantsNotInCart, isEnoughPoints, product,
}) {
  const amountAvailableForPlayer = item.max_per_player - item.total_buyed_by_current_player;
  const totalAmountAvailable = Math.min(item.calculated_stock_remains, amountAvailableForPlayer);
  const currentAmountAvailableForPlayer = amountAvailableForPlayer - item.amount;

  const hasPersonalLimit = item.max_per_player > 0;
  const isPersonalLimit = currentAmountAvailableForPlayer <= 0;
  const isStockLimit = item.amount >= item.calculated_stock_remains;
  const isOutOfStock = item.calculated_stock_remains <= 0;
  const isBooster = item.visual_theme === 'booster';
  const isCodeExpired = item.available_codes && !item.pending_codes && !isBooster;

  const canDecrease = (item.amount >= 1) && !isCodeExpired;
  const canIncrease = (item.amount < totalAmountAvailable) && isEnoughPoints && !isCodeExpired;

  const handleQuantityChange = (diff) => {
    const newItem = item;

    newItem.amount += diff;
    onItemChange(newItem);
  };

  const errorMessage = () => {
    if (isOutOfStock || isCodeExpired) {
      return <span>Нет в наличии</span>;
    }
    if (isPersonalLimit && !isCodeExpired && item.max_per_player > 1) {
      return (
        <span>{`Лимит - ${item.max_per_player} шт. для каждого игрока`}</span>
      );
    }
    if (isStockLimit && !isCodeExpired) {
      return (
        <span>
          {`Можешь купить максимум\xA0${item.calculated_stock_remains}\xA0шт., больше нет в наличии`}
        </span>
      );
    }

    return null;
  };

  return (
    <div className={styles.CartItem}>
      <div className={styles.CartItem__row}>
        <span className={styles.CartItem__title}>{item.short_title || item.full_title}</span>

        {item.max_per_player === 1 && !canDecrease && (
          <div className={styles.CartItem__quantityWrap}>
            <button
              className={cn(
                styles.CartItem__chooseButton,
                styles.CartItem__chooseButton_choose,
              )}
              onClick={() => handleQuantityChange(1)}
              type="button"
            >
              <IconSvg icon="radio-on-color-circle" />
              <span>Выбрать</span>
            </button>
          </div>
        )}

        {item.max_per_player === 1 && canDecrease && (
          <div className={styles.CartItem__quantityWrap}>
            <button
              className={cn(
                styles.CartItem__chooseButton,
                styles.CartItem__chooseButton_unchoose,
              )}
              onClick={() => handleQuantityChange(-1)}
              type="button"
            >
              <IconSvg icon="radio-on-color" />
              <span>Выбрано</span>
            </button>
          </div>
        )}

        {item.max_per_player > 1 && (
          <div className={styles.CartItem__quantityWrap}>
            <button
              className={cn(styles.CartItem__quantityButton, styles.CartItem__quantityButton_minus)}
              onClick={() => handleQuantityChange(-1)}
              disabled={!canDecrease}
              type="button"
            >
              <IconSvg icon="minus" />
            </button>

            <span className={styles.CartItem__quantity}>{item.amount}</span>

            <button
              className={cn(styles.CartItem__quantityButton, styles.CartItem__quantityButton_plus)}
              onClick={() => handleQuantityChange(1)}
              disabled={!canIncrease}
              type="button"
            >
              <IconSvg icon="plus" />
            </button>
          </div>
        )}
      </div>
      <div className={styles.CartItem__error}>
        {errorMessage()}
      </div>
    </div>
  );
}

PruductOrderCartItem.propTypes = {
  item: PropTypes.object,
  onItemChange: PropTypes.func.isRequired,
  variantsNotInCart: PropTypes.array,
  isEnoughPoints: PropTypes.bool,
  product: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};
