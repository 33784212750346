import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import { createGlobalStyle } from 'styled-components';
// import actionCable from 'actioncable';
// import { toJS } from 'mobx';

import IconSvg from '../../../../common/javascripts/components/IconSvg';
import PlayerMenu from './components/PlayerMenu';
import TeamMenu from './components/TeamMenu';
import TopbarPlayerSection from './components/PlayerSection';
import TopbarTeamSection from './components/TeamSection';
// import playerViewStore from '../../stores/playerViews';

// import TheGame5YearsImage from '../../../images/5-years-game.png';

import styles from './Topbar.module.scss';
import QRShowBtn from '../QRShowBtn';
import BottomSlideUp from '../BottomSlideUp';
import AuthScreen from '../../screens/Auth';
import { authenticityToken } from '../../../../common/javascripts/fetchOptions';
import { expires60min, setCookie } from '../../helpers/cookie';

const teamAPI = {
  newTeamPath: '/teams/new',
  teamProfilePath: (id) => `/teams/${id}`,
  ratingsPath: '/ratings/teams',
};
const playerAPI = {
  editProfilePath: (id) => `/players/${id}/edit`,
  profilePath: (id) => `/players/${id}`,
  editPassword: (id) => `/players/${id}/edit_password`,
};

export default function Topbar({
  player, team, canInviteTeamMember, showTeamSection, settings,
}) {
  const GlobalStyles = createGlobalStyle`
    html {
      --admin-color-program: ${settings.admin_color_program.hex};
      --admin-bg-color-program: ${settings.admin_bg_color_program.hex};
      --admin-header-color-program: ${settings.admin_header_color_program.hex};
      --admin-text-color-program: ${settings.admin_text_color_program.hex};
      --admin-color-quest: ${settings.admin_color_quest.hex};
      --admin-bg-color-quest: ${settings.admin_bg_color_quest.hex};
      --admin-header-color-quest: ${settings.admin_header_color_quest.hex};
      --admin-color-nav: ${settings.admin_color_nav.hex};
      --admin-bg-color-player-menu: ${settings.admin_bg_color_player_menu.hex};
      --admin-bg-color-virtual-card: ${settings.admin_bg_color_virtual_card?.hex};
    }
  `;
  // const CableApp = {};

  // CableApp.cable = actionCable.createConsumer('/cable');

  const [showPlayerMenu, setShowPlayerMenu] = useState(false);
  const [showTeamMenu, setShowTeamMenu] = useState(false);
  const isMenuOpened = showPlayerMenu || showTeamMenu;
  const isDefaultPlayerMenuColor = settings.admin_bg_color_player_menu.hex.toUpperCase() === '#A0A9B2';
  const [showSlideUp, setShowSlideUp] = useState(false);

  const handlePlayerClick = (e) => {
    e.preventDefault();
    if (player) {
      setShowTeamMenu(false);
      setShowPlayerMenu(!showPlayerMenu);
    } else {
      setShowSlideUp(true);
      setCookie('return_to', window.location.href, { expires: expires60min() });
    }
  };

  const handleTeamClick = (e) => {
    e.preventDefault();
    setShowPlayerMenu(false);
    setShowTeamMenu(!showTeamMenu);
  };

  const handleBackLinkClick = () => {
    window.history.back();
  };

  // const theGameAnniversaryImage = (
  //   <img
  //     className={styles.Topbar__menuAnniversaryImage}
  //     src={TheGame5YearsImage}
  //     alt="The game anniversary"
  //   />
  // );

  // useEffect(() => {
  //   try {
  //     CableApp.cable.subscriptions.create(
  //       {
  //         channel: 'NotificationsChannel',
  //         player_id: player.id,
  //       },
  //       {
  //         received: (data) => {
  //           console.log('TopBar received =>', data);
  //           if (data.notification.id) {
  //             console.log('TopBar data.notification.id =>', data.notification.id);
  //             playerViewStore.fetchUnread();
  //           }
  //         },
  //       },
  //     );
  //     console.log('TopBar => ', CableApp);
  //   } catch (error) {
  //     console.log('TopBar => ', error);
  //   }
  // }, []);

  return (
    <>
      <div className={cn(styles.Topbar, { [styles.Topbar_menuOpened]: isMenuOpened })}>
        <GlobalStyles />
        <div className={styles.Topbar__main}>

          <div className={styles.Topbar__backWrap}>
            <button className={styles.Topbar__backLink} onClick={handleBackLinkClick} type="button">
              <IconSvg icon="back" className={styles.Topbar__backIcon} />
            </button>
          </div>

          {player && (
            <div className={styles.Topbar__qrWrap}>
              <QRShowBtn label="Мой QR-код" labelVisible={false} value={`${player.phone}`} />
            </div>
          )}

          {player && (
            <TopbarPlayerSection
              onClick={handlePlayerClick}
              isMenuOpened={showPlayerMenu}
              avatarUrl={player.avatar_url}
              styles={styles}
            />
          )}

          {!player && (
            <TopbarPlayerSection
              onClick={handlePlayerClick}
              isMenuOpened={showPlayerMenu}
              styles={styles}
              guest
            />
          )}

          {player && showTeamSection && (
            <TopbarTeamSection
              onClick={handleTeamClick}
              isMenuOpened={showTeamMenu}
              team={team}
              teamAPI={teamAPI}
              styles={styles}
            />
          )}

          <a href="/help/faq" className={styles.Topbar__help}>
            <IconSvg icon="topbar-help" className={styles.Topbar__iconCircle} />
          </a>
        </div>

        {player && (
          <div className={cn(styles.Topbar__menu, { [styles.Topbar__menu_show]: showPlayerMenu, [styles.Topbar__defaultColorPlayerMenu]: isDefaultPlayerMenuColor })}>
            <PlayerMenu player={player} playerAPI={playerAPI} />
            {/* {theGameAnniversaryImage} */}
          </div>
        )}

        {
          team
          && (
            <div className={cn(
              styles.Topbar__menu,
              { [styles.Topbar__menu_show]: showTeamMenu },
            )}
            >
              <TeamMenu
                team={team}
                player={player}
                teamAPI={teamAPI}
                playerAPI={playerAPI}
                canInviteTeamMember={canInviteTeamMember}
              />
              {/* {theGameAnniversaryImage} */}
            </div>
          )
        }
      </div>
      <BottomSlideUp show={showSlideUp} onClose={() => setShowSlideUp(false)}>
        <AuthScreen
          askBirthday={(settings.age_restriction || 0) > 0}
          token={authenticityToken()}
          url="/"
          welcomeText={settings}
          defaultStep={1}
          viewHeader={false}
        />
      </BottomSlideUp>
    </>
  );
}

Topbar.propTypes = {
  player: PropTypes.object.isRequired,
  team: PropTypes.object,
  canInviteTeamMember: PropTypes.bool,
  canJoinCreateTeam: PropTypes.bool,
  showTeamSection: PropTypes.bool,
  settings: PropTypes.shape({
    age_restriction: PropTypes.number,
    admin_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_header_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_text_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_color_quest: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_quest: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_header_color_quest: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_color_nav: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_player_menu: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_virtual_card: PropTypes.shape({
      hex: PropTypes.string,
    }),
  }).isRequired,
};

Topbar.defaultProps = {
  team: {},
  canInviteTeamMember: true,
  showTeamSection: true,
};
