import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import IconSvg from '../../../../common/javascripts/components/IconSvg';
import playerViewStore from '../../stores/playerViews';

import BottomSlideUp from '../BottomSlideUp';
import AuthScreen from '../../screens/Auth';
import { authenticityToken } from '../../../../common/javascripts/fetchOptions';
import { expires60min, setCookie } from '../../helpers/cookie';

function NotificationNavigation({ activeTab, guest, settings }) {
  const [showSlideUp, setShowSlideUp] = useState(false);
  const tabs = [
    [
      'Новости',
      '/notifications/news',
      () => (playerViewStore.unreadNews > 0 ? playerViewStore.unreadNews : null),
      () => null,
    ],
    [
      'Уведомления',
      '/notifications/feed',
      () => (playerViewStore.unreadNotifications > 0 ? playerViewStore.unreadNotifications : null),
      () => null,
    ],
  ];

  const guestTabs = [
    [
      'Новости',
      '/notifications/news',
      () => null,
      () => null,
    ],
    [
      'Уведомления',
      '/',
      () => null,
      (e) => {
        e.preventDefault();
        setShowSlideUp(true);
        setCookie('return_to', '/notifications/feed', { expires: expires60min() });
      },
    ],
  ];
  const playerTabs = guest ? guestTabs : tabs;

  const slideUpClose = () => setShowSlideUp(false);

  return (
    <div className="pageContent__navTabs navTabs">
      {
        playerTabs.map(([tabLabel, tabHref, tabBadge, tabClick], index) => {
          const className = classNames('navTabs__itemNotify', { navTabs__itemNotify_active: activeTab === index });
          const tabBadgeContent = tabBadge();

          return (
            <a href={tabHref} className={className} key={tabHref} onClick={tabClick}>
              <span className="navTabs__itemTextNotify">
                {tabLabel}
                {tabBadgeContent && <span className="navTabs__itemBadge">{tabBadgeContent}</span>}
              </span>
            </a>
          );
        })
      }

      <a href="https://t.me/spotwayru" className="navTabs__itemNotify navTabs__itemNotify_shrink" target="_blank" rel="noreferrer">
        <IconSvg icon="contact-telegram" className="navTabs__chatIcon" />
        <span className="navTabs__itemTextNotify">
          Канал
        </span>
      </a>

      {guest && (
        <BottomSlideUp show={showSlideUp} onClose={slideUpClose}>
          <AuthScreen
            askBirthday={(settings.age_restriction || 0) > 0}
            token={authenticityToken()}
            url="/"
            welcomeText={settings}
            defaultStep={1}
            viewHeader={false}
          />
        </BottomSlideUp>
      )}
    </div>
  );
}

NotificationNavigation.propTypes = {
  activeTab: PropTypes.number,
  guest: PropTypes.bool,
  settings: PropTypes.shape({
    age_restriction: PropTypes.number,
    admin_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_header_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_text_color_program: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_color_quest: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_quest: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_header_color_quest: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_color_nav: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_player_menu: PropTypes.shape({
      hex: PropTypes.string,
    }),
    admin_bg_color_virtual_card: PropTypes.shape({
      hex: PropTypes.string,
    }),
  }).isRequired,
};

NotificationNavigation.defaultProps = {
  activeTab: 0,
  guest: false,
};

export default observer(NotificationNavigation);
