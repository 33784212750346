import setYear from 'date-fns/setYear';
import getYear from 'date-fns/getYear';
import setDate from 'date-fns/setDate';
import getDate from 'date-fns/getDate';
import setMonth from 'date-fns/setMonth';
import getMonth from 'date-fns/getMonth';
import addDays from 'date-fns/addDays';
import isSameDay from 'date-fns/isSameDay';
import isValid from 'date-fns/isValid';
import isBefore from 'date-fns/isBefore';
import { utcToZonedTime } from 'date-fns-tz';

const dateToMoscowZone = (date) => utcToZonedTime(date, 'Europe/Moscow');
const currentMoscowDate = dateToMoscowZone(new Date());

const mergeDateWithTime = (date, time) => {
  let dateToReturn = new Date(time);
  dateToReturn = setYear(dateToReturn, getYear(date));
  dateToReturn = setMonth(dateToReturn, getMonth(date));
  dateToReturn = setDate(dateToReturn, getDate(date));
  return dateToReturn;
};

const isEventFinished = (date, startTime, endTime) => {
  if (!isValid(startTime) || !isValid(endTime) || !isValid(date)) return true;

  let dateForEventEnd = date;
  const startTimeInTimeZone = dateToMoscowZone(startTime);
  const endTimeInTimeZone = dateToMoscowZone(endTime);

  // Event end next day?
  if (!isSameDay(startTimeInTimeZone, endTimeInTimeZone)) {
    dateForEventEnd = addDays(date, 1);
  }

  return isBefore(
    mergeDateWithTime(dateForEventEnd, dateToMoscowZone(endTime)),
    dateToMoscowZone(new Date()),
  );
};

export {
  dateToMoscowZone,
  currentMoscowDate,
  mergeDateWithTime,
  isEventFinished,
};
