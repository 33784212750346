/* eslint-disable react/forbid-prop-types */
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Avatar from '../../../Avatar';
import TopbarCloseMenu from '../CloseMenu';

export default function TopbarPlayerSection({
  isMenuOpened, avatarUrl, onClick, styles, guest,
}) {
  return (
    <button className={styles.Topbar__player} onClick={onClick} type="button">
      <span className={styles.Topbar__profileText}>Я</span>
      <div className={cn(styles.Topbar__avatar, styles.Topbar__avatar_player)}>
        {
          !guest && isMenuOpened && <TopbarCloseMenu />
        }
        <Avatar type="player" avatar={avatarUrl} />
      </div>
    </button>
  );
}

TopbarPlayerSection.propTypes = {
  isMenuOpened: PropTypes.bool.isRequired,
  avatarUrl: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  styles: PropTypes.object,
  guest: PropTypes.bool,
};

TopbarPlayerSection.defaultProps = {
  avatarUrl: null,
  styles: {},
  guest: false,
};
