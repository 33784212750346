import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { ru } from 'date-fns/esm/locale';

import EventTime from '../../screens/Event/components/EventTime';
import styles from './Address.module.scss';

export default function Address({
  title, address, lat, lon, startAt, endAt,
}) {
  const hasMapLink = lat && lon;
  const hasTime = startAt && endAt;
  const mapLink = `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`;

  return (
    <div className={styles.Address}>
      <div className={styles.Address__title}>
        {title}
      </div>

      <div className={styles.Address__row}>
        <div className={styles.Address__data}>
          {address}
        </div>
        {
          hasMapLink
          && (
            <a href={mapLink} rel="noreferrer" target="_blank" className={styles.Address__onMapLink}>
              На карте
            </a>
          )
        }
      </div>
      {hasTime && (
        <div className={styles.Address__row}>
          <div className={styles.Address__dateTime}>
            {/* <div className={styles.EventCard__date}>
              {format(new Date(day), 'd MMMM (EEEEEE)', { locale: ru })}
            </div>
            */}
            <EventTime
              startAt={startAt}
              endAt={endAt}
              className={styles.Address__inCard}
            />
          </div>
        </div>
      )}
    </div>
  );
}

Address.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  lat: PropTypes.string,
  lon: PropTypes.string,
  startAt: PropTypes.string,
  endAt: PropTypes.string,
};

Address.defaultProps = {
  lat: null,
  lon: null,
  startAt: null,
  endAt: null,
};
