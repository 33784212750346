function isTaskModerator(player) {
  if (!player) return false;

  return (player.roles || []).includes('task_moderator');
}

function isOrdersModerator(player) {
  if (!player) return false;

  return (player.roles || []).includes('orders_moderator');
}

function isEventsModerator(player) {
  if (!player) return false;

  return (player.roles || []).includes('events_moderator');
}

function isPromoterOneTask(player, taskId) {
  if (!player) return false;

  if (!((player.roles || []).includes('promoter_one_task'))) return false;

  if (taskId !== player.moderated_task_id) return false;

  return true;
}

function isBracletsModerator(player) {
  if (!player) return false;

  return (player.roles || []).includes('braclet_moderator');
}

export {
  isTaskModerator,
  isOrdersModerator,
  isEventsModerator,
  isPromoterOneTask,
  isBracletsModerator,
};
