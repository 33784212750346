import {
  action,
  makeObservable,
  observable,
  runInAction,
  onBecomeObserved,
} from 'mobx';

import ProgramMemberApi from './programMemberApi';
import currentPlayerStore from './currentPlayerStore';

const MODERATED_PROGRAM_RECIPIENT = 'moderated_program_recipient';
const MODERATED_PROGRAM_MEMBER = 'moderated_program_members';

class ModeratedProgramMember {
  programMember = [];

  recipient = {};

  isProgramMemberLoading = false;

  constructor(programMemberApi) {
    makeObservable(this, {
      programMember: observable,
      recipient: observable,
      isProgramMemberLoading: observable,

      initProgramMember: action,
      takeBraclet: action,
      restoreBraclet: action,
    });
    this.programMemberApi = programMemberApi;
    this.currentPlayerStore = currentPlayerStore;

    this.initProgramMember();
  }

  initProgramMember = () => {
    this.isProgramMemberLoading = true;
    const recipient = JSON.parse(localStorage.getItem(MODERATED_PROGRAM_RECIPIENT));
    const members = JSON.parse(localStorage.getItem(MODERATED_PROGRAM_MEMBER));
console.log('initProgramMember=>recipient=>', recipient);
console.log('initProgramMember=>members=>', members);

    if (recipient) {
      this.recipient = recipient;
    }
    if (members) {
      this.programMember = members;
    }
    this.isProgramMemberLoading = false;
  }

  setProgramMember = (programMember) => {
    if (programMember) {
      this.recipient = programMember?.recipient;
      this.programMember = programMember?.members;

      localStorage.setItem(MODERATED_PROGRAM_RECIPIENT, JSON.stringify(programMember.recipient));
      localStorage.setItem(MODERATED_PROGRAM_MEMBER, JSON.stringify(programMember.members));
    } else {
      this.recipient = {};
      this.programMember = [];

      localStorage.removeItem(MODERATED_PROGRAM_RECIPIENT);
      localStorage.removeItem(MODERATED_PROGRAM_MEMBER);
    }
  };

  resetProgramMember = () => {
    this.setProgramMember(null);
  }

  fetchProgramMember = async (phone) => {
    this.isProgramMemberLoading = true;
    const result = await this.programMemberApi.searchOnRosafest(phone);

    runInAction(() => {
      if (result) {
        this.setProgramMember(result);
      }
      this.isProgramMemberLoading = false;
    });

    if (result) {
      return result;
    }
    return null;
  }

  fetchProgramMemberOnSpotwayByScan = async (link) => {
    this.isProgramMemberLoading = true;
    const result = await this.programMemberApi.searchOnSpotwayByScan(link);
console.log('fetchProgramMemberOnSpotwayByScan=>result=>', result);

    runInAction(() => {
      if (result) {
        this.setProgramMember(result);
      }
      this.isProgramMemberLoading = false;
    });

    if (result) {
      return result;
    }

    return null;
  }

  addProgramMember = async (phone) => {
    this.isProgramMemberLoading = true;
    const { player } = this.currentPlayerStore;
    const result = await this.programMemberApi.addProgramMember(player, phone);

    runInAction(() => {
      if (result) {
        this.setProgramMember(result);
      }
      this.isProgramMemberLoading = false;
    });

    if (result) {
      return result;
    }
    return null;
  }

  takeBraclet = async () => {
    const recipient = this.recipient;
    const members = this.programMember;
    const { player } = this.currentPlayerStore;
    let result = null;

    if (player && recipient?.member_phone && members?.length > 0) {
      this.isProgramMemberLoading = true;

      const resultOnSpotway = await this.programMemberApi.takeBracletOnSpotway(player, recipient, members);

      if (resultOnSpotway) {
        result = resultOnSpotway;

        await this.programMemberApi.takeBracletOnRosafest(player, recipient, result?.members);
      }

      if (!resultOnSpotway) {
        const resultOnRosafest = await this.programMemberApi.takeBracletOnRosafest(player, recipient, members);

        result = resultOnRosafest;
      }

      runInAction(() => {
        if (result) {
          this.setProgramMember(result);
        }
        this.isProgramMemberLoading = false;
      });

      if (result) {
        return result;
      }
    }

    return null;
  }

  restoreBraclet = async () => {
    console.log('restoreBraclet in process...');
  }
}

export default new ModeratedProgramMember(new ProgramMemberApi());
