import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { observer } from 'mobx-react';

import styles from './ModeratedProgramMember.module.scss';
import { isPhoneValid, phoneMask } from '../../helpers/phone';
import ModeratedProgramMemberStore from '../../stores/moderatedProgramMember';

function SearchProgramMemberForm({ onProgramMemberNotFound, onProgramMemberFound }) {
  const {
    isProgramMemberLoading,
    fetchProgramMember,
    addProgramMember,
  } = ModeratedProgramMemberStore;

  const [phone, setPhone] = useState('');
  const [canSearch, setCanSearch] = useState(false);
  const [canAddProgramMember, setCanAddProgramMember] = useState(null);

  const handleBeforeMaskedStateChange = ({ nextState }) => {
    let { value } = nextState;
    const { enteredString } = nextState;
    const digits = enteredString ? enteredString.replace(/[^0-9]/g, '') : '';

    if (digits.length >= 10) {
      const maskPhone = digits.slice(-10);

      value = `+7 (${maskPhone.slice(0, 3)}) ${maskPhone.slice(3, 6)}-${maskPhone.slice(6, 8)}-${maskPhone.slice(8, 10)}`;
    }

    return {
      ...nextState,
      value,
    };
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
    setCanAddProgramMember(false);
  };

  const handleAddPlayerToProgramMemberClick = async () => {
    try {
      const result = await addProgramMember(phone);

      if (result) {
        onProgramMemberFound('Участник добавлен');
        setCanAddProgramMember(false);
      } else {
        onProgramMemberNotFound();
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
    setCanAddProgramMember(false);
  };

  const handleSearchClick = async () => {
    try {
      const result = await fetchProgramMember(phone);

      if (result) {
        onProgramMemberFound('Участник найден');
      } else {
        onProgramMemberNotFound('Участник не найден');
        setCanAddProgramMember(true);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  useEffect(() => {
    setCanSearch(isPhoneValid(phone));
  }, [phone]);

  return (
    <form className={styles.ModeratedProgramMember__searchForm}>
      <div className={styles.ModeratedProgramMember__phoneInputWrap}>
        <InputMask
          mask={phoneMask}
          alwaysShowMask
          onChange={handlePhoneChange}
          value={phone}
          beforeMaskedStateChange={handleBeforeMaskedStateChange}
        >
          <input
            className="input input_attention"
            type="text"
            pattern="[0-9]*"
            inputMode="numeric"
          />
        </InputMask>
      </div>
      <button
        className="button button_fullwidth button_secondary"
        disabled={!canSearch || isProgramMemberLoading}
        onClick={handleSearchClick}
        type="submit"
      >
        Поиск
      </button>

      {canAddProgramMember && canSearch && (
        <>
          <button
            type="button"
            className="button button_fullwidth button_secondary"
            disabled={!canSearch || isProgramMemberLoading}
            onClick={handleAddPlayerToProgramMemberClick}
          >
            Добавить в участники
          </button>
          </>
      )}
    </form>
  );
}

SearchProgramMemberForm.propTypes = {
  onProgramMemberFound: PropTypes.func.isRequired,
  onProgramMemberNotFound: PropTypes.func.isRequired,
};

export default observer(SearchProgramMemberForm);
