import React, { useState } from 'react';
import PropTypes from 'prop-types';

import BecomePartnerButton from '../../../components/BecomePartnerButton';
import PageHeader from '../../../components/PageHeader';
import PageContentOtherBg from '../../../components/PageContentOtherBg';

import PartnerCardsList from './components/CardsList';
import TagsFilter from '../../../components/TagsFilter/TagsFilter';
import TagsFilterList from '../../../components/TagsFilterList/TagsFilterList';
import TagsFilterButton from '../../../components/TagsFilterButton/TagsFilterButton';

import * as styles from './List.module.scss';
import IconSvg from '../../../../../common/javascripts/components/IconSvg/IconSvg';

export default function PartnersListScreen({ partners, header, tags, is_program, img_url_bonus }) {
  const [filter, setFilter] = useState(0);
  const [filteredPartners, setFilteredPartners] = useState(partners);

  const qntElemTags = partners.map((partner) => partner.tags.map((tag) => tag.title))
    .filter((p) => p.length > 0)
    .flatMap((p) => p)
    .reduce((acc, el) => { acc[el] = (acc[el] || 0) + 1; return acc; }, {});


  const handleFilterClick = (newFilter) => {
    if (newFilter === 0) {
      setFilter(newFilter);
      setFilteredPartners(partners);

      return;
    }
    if (newFilter !== filter) {
      setFilter(newFilter);

      const newPartners = partners.filter((partner) => partner.tags.filter((tag) => tag.id === newFilter).length > 0);

      setFilteredPartners(newPartners);
    }
  };

  return (
    <PageContentOtherBg>
      <BecomePartnerButton top bottom={false} />

      {img_url_bonus && (
        <PageHeader>
          <div className={styles.PartnersListScreen__logo}>
            <img src={img_url_bonus} alt="logo_bonus" />
          </div>
        </PageHeader>
      )}

      <PageHeader>
        <div className={styles.PartnersListScreen__header}>
          <h1>
            {header}
          </h1>
          {is_program && (
            <IconSvg icon="sbp-without-text" />
          )}
        </div>
      </PageHeader>

      {tags.length > 0 && (
        <TagsFilter>
          <TagsFilterList>
            <li>
              <TagsFilterButton
                label={`Все (${partners.length})`}
                onClick={() => handleFilterClick(0)}
                active={filter === null || filter === 0}
                className={(filter === null || filter === 0) ? styles.PartnersListScreen_active : ''}
              />
            </li>
            {tags.map((tag) => (
              <li key={tag.id}>
                <TagsFilterButton
                  label={`${tag.title} (${qntElemTags[tag.title]})`}
                  onClick={() => handleFilterClick(tag.id)}
                  active={filter === tag.id}
                  className={(filter === tag.id) ? styles.PartnersListScreen_active : ''}
                />
              </li>
            ))}
          </TagsFilterList>
        </TagsFilter>
      )}

      <PartnerCardsList items={filteredPartners} />
      <BecomePartnerButton />
    </PageContentOtherBg>
  );
}

PartnersListScreen.propTypes = {
  header: PropTypes.string,
  partners: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })).isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  is_program: PropTypes.bool,
  img_url_bonus: PropTypes.string,
};

PartnersListScreen.defaultProps = {
  header: 'Партнеры',
  tags: [],
  is_program: false,
  img_url_bonus: '',
};
