export const variantToCartItem = (product, variant, params = {}) => {
  const amountAvailableForPlayer = variant.max_per_player - variant.total_buyed_by_current_player;
  const totalAmountAvailable = Math.min(variant.calculated_stock_remains, amountAvailableForPlayer);
  const defaultAmount = totalAmountAvailable > 0 ? 0 : 0;

  let amount = params.amount || defaultAmount;

  if (amount > totalAmountAvailable) {
    amount = Math.max(0, totalAmountAvailable);
  }

  return {
    id: params.id || 1,
    amount,
    variant_id: variant.id,
    short_title: variant.short_title,
    full_title: variant.full_title,
    calculated_stock_remains: variant.calculated_stock_remains,
    max_per_player: variant.max_per_player,
    total_buyed_by_current_player: variant.total_buyed_by_current_player,
    available_codes: variant.available_codes,
    pending_codes: variant.pending_codes,
    count_pending_codes: variant.count_pending_codes,
    visual_theme: variant.visual_theme,
    booster: variant.booster,
    cost_player_points: product.cost_player_points,
    cost_team_points: product.cost_team_points,
  };
};

export const calcTotal = (cart, product) => {
  // eslint-disable-next-line newline-after-var
  let playerPoints = 0;
  let teamPoints = 0;
  let totalAmounts = 0;

  cart.map((item) => {
    playerPoints += (product.cost_player_points || 0) * item.amount;
    teamPoints += (product.cost_team_points || 0) * item.amount;
    totalAmounts += item.amount;
    return null;
  });

  return [playerPoints, teamPoints, totalAmounts];
};
