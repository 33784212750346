import React from 'react';
import PropTypes from 'prop-types';

import PageCardsList from '../../../../../components/PageCardsList';
import Card from '../../../../../components/Card';
import ContentNotice from '../../../../../components/ContentNotice';
import NotificationTaskCard from '../../../../../components/NotificationCards/Task';
import PlayerRate from '../../../../../components/PlayerRate';

import TaskTitle from '../../../components/Title';
import TaskTime from '../../../components/Time';
import TaskCost from '../../../components/Cost';
import TaskIdFavStatus from '../../../components/IdFavStatus';
import BoostersList from '../../../components/BoostersList';

import { isPromoterOneTask, isTaskModerator } from '../../../../../helpers/players';
import Steps from './Steps';
import ModeratedSteps from './ModeratedSteps';

import styles from './Task.module.scss';

// const shopPath = '/shop/products';
// const ratingPath = '/ratings/teams';
// const topAwardsPath = '/ratings/top_awards';

export default function Task({
  task, player, contact, token, onTaskFetch, onTaskAnswer, setting,
}) {
  const isTaskFailed = task.status === 'failed';
  const isTaskAvailable = task.status === 'available';
  const secondChanceBoosters = task.boosters.filter((booster) => booster.type === 'second_chance');

  return (
    <>
      {(isPromoterOneTask(player, task.id) || isTaskModerator(player)) && (
        <ModeratedSteps
          task={task}
          player={player}
          token={token}
          contact={contact}
          onTaskAnswer={onTaskAnswer}
          onTaskFetch={onTaskFetch}
        />
      )}
      <PageCardsList space={2}>
        <li>
          <Card noShadow>
            <TaskIdFavStatus bottomSpace task={task} />
            <TaskTitle task={task} />
            <TaskTime task={task} />

            {/* <ContentNotice bottomSpace uid="taskReward">
              {'Монеты обменивай '}
              <a href={shopPath} target="_blank" rel="noreferrer">на&nbsp;призы</a>
              {', очки продвигают твою команду '}
              <a href={ratingPath} target="_blank" rel="noreferrer">в&nbsp;рейтинге</a>
              {' в борьбе '}
              <a href={topAwardsPath} target="_blank" rel="noreferrer">за&nbsp;ТОП-призы</a>
              .
            </ContentNotice> */}

            <ContentNotice bottomSpace uid="taskReward">
              <div
                // className={styles.WinnerAward__prize}
                dangerouslySetInnerHTML={{ __html: setting?.task_show_notice || 'ЗАПОЛНИ В ОСНОВНЫХ НАСТРОЙКАХ' }}
              />
            </ContentNotice>

            <TaskCost task={task} />
          </Card>
        </li>

        {(!isTaskFailed || (isPromoterOneTask(player, task.id) || isTaskModerator(player))) && (
          <Steps
            task={task}
            player={player}
            token={token}
            contact={contact}
            onTaskAnswer={onTaskAnswer}
            onTaskFetch={onTaskFetch}
          />
        )}

      </PageCardsList>

      {
        (!isTaskAvailable && !(isPromoterOneTask(player, task.id) || isTaskModerator(player)))
        && (
          <div className={styles.Task__notification}>
            <NotificationTaskCard notification={task.last_notification_for_player} />
          </div>
        )
      }
      {
        (!isTaskAvailable)
        && (
          <div className={styles.Task__playerRate}>
            <PlayerRate
              paths={task.rate_paths}
              rate={task.rate}
              header="Понравилось задание?"
              className={styles.Task__playerRate}
            />
          </div>
        )
      }
      {
        (!isTaskAvailable && !(isPromoterOneTask(player, task.id) || isTaskModerator(player)))
        && (
          <>
            <div className={styles.Task__secondChance}>
              <BoostersList
                taskId={task.id}
                boosters={secondChanceBoosters}
                onBoosterUse={onTaskFetch}
              />
            </div>

            <div className={styles.Task__actions}>
              <a href="/tasks" className="button button_fullwidth button_large">
                Следующее задание
              </a>
            </div>
          </>
        )
      }
    </>
  );
}

Task.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    steps_count: PropTypes.number,
    completed_steps_count: PropTypes.number,
    rate: PropTypes.bool,
    rate_paths: PropTypes.shape({
      player_rate_up: PropTypes.string,
      player_rate_down: PropTypes.string,
      cancel_player_rate: PropTypes.string,
    }).isRequired,
    last_notification_for_player: PropTypes.shape({
      id: PropTypes.number,
    }),
    status: PropTypes.string,
    boosters: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
    })),
    task_steps: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      state: PropTypes.string,
    })),
  }).isRequired,
  contact: PropTypes.arrayOf(PropTypes.shape({
    phone: PropTypes.string,
  })).isRequired,
  player: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  token: PropTypes.string.isRequired,
  onTaskFetch: PropTypes.func.isRequired,
  onTaskAnswer: PropTypes.func.isRequired,
  setting: PropTypes.shape({
    img_url_card: PropTypes.string.isRequired,
    title_card: PropTypes.string.isRequired,
    text_card: PropTypes.string.isRequired,
    description_card: PropTypes.string.isRequired,
    task_show_notice: PropTypes.string.isRequired,
  }).isRequired,
};
