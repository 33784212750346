import React, { useState } from 'react';

import cn from 'classnames';

import * as styles from './OAuth.module.scss';
import TbankButton from '../Buttons/TbankButton/TbankButton';
import YandexButton from '../Buttons/YandexButton/YandexButton';
import VkButton from '../Buttons/VkButton/VkButton';

export default function OAuth() {
  const [otherAuth, setOtherAuth] = useState(false);
  const [appHost, setAppHost] = useState(process.env.APP_HOST);

  const handleYandexCkick = () => {
    window.location = `https://${appHost}/auth/yandex`;
  };

  const handleTbankCkick = () => {
    window.location = `https://${appHost}/auth/tinkoff_id`;
  };

  const handleVkCkick = () => {
    window.location = `https://${appHost}/auth/vkontakte_id`;
  };

  const handleOtherAuthClick = () => {
    setOtherAuth(!otherAuth);
  };

  return (
    <div className={styles.OAuth}>
      {/* <h3 className={styles.OAuth__header}>
        Вход в личный кабинет
      </h3> */}
      <div className={styles.OAuth__rowSpacing}>
        <TbankButton onClick={handleTbankCkick} />
      </div>
      {otherAuth && (
        <>
          <div className={styles.OAuth__rowSpacing}>
            <YandexButton onClick={handleYandexCkick} />
          </div>
          <div className={styles.OAuth__rowSpacing}>
            <VkButton onClick={handleVkCkick} />
          </div>
          <div className={styles.OAuth__rowSpacingZero}>
            <button
              type="button"
              className={cn('button button_large button_fullwidth', styles.OAuth__otherButton)}
              onClick={handleOtherAuthClick}
            >
              Свернуть
            </button>
          </div>
        </>
      )}
      {!otherAuth && (
        <div className={styles.OAuth__rowSpacingZero}>
          <button
            type="button"
            className={cn('button button_large button_fullwidth', styles.OAuth__otherButton)}
            onClick={handleOtherAuthClick}
          >
            Еще способы
          </button>
        </div>
      )}
    </div>
  );
};
