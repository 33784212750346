import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './TbankReAuth.module.scss';
import CardHeader from '../CardHeader/CardHeader';

export default function TbankReAuth({ className, onClose }) {
  const handleTbankRedirect = () => {
    if (onClose) onClose();
    window.open(`${process.env.TBANK_PREMIUM_REDIRECT}`, '_blank');
  };

  return (
    <div
      className={
        cn(styles.TbankReAuth,
          { [className]: className },
        )}
    >
      <CardHeader bottomSpace="s" className={styles.TbankReAuth__center}>
        <h2>Привилегии от Т-Банка</h2>
      </CardHeader>

      <div
        className={styles.TbankReAuth__image}
      />
      <div
        className={styles.TbankReAuth__contentWrap}
      >
        По тэгу VIP держателям сервиса&nbsp;
        <strong>Premium</strong>
        &nbsp;
        и&nbsp;
        <strong>Private</strong>
        &nbsp;
        от Т-Банка доступны особые задания и лимитированный мерч в магазине квеста.
      </div>
      <div
        className={styles.TbankReAuth__contentWrap}
      >
        Выйдите из профиля игры, и повторно войдите через T-ID. Мы обновим данные о подписке, и вы получите расширенные возможности в квесте.&nbsp;
        <strong>
          Номер телефона в квесте должен совпадать с профилем в Т-Банке.
        </strong>
      </div>

      <div
        className={styles.TbankReAuth__buttonWrap}
      >
        <button
          type="button"
          className={cn('button button_large button_fullwidth', styles.TbankReAuth__tbankOk)}
          onClick={() => { window.location = `https://${process.env.APP_HOST}/auth/tinkoff_id`; }}
        >
          Выйти из профиля
        </button>
        <button
          type="button"
          className={cn('button button_large button_fullwidth button_secondary', styles.TbankReAuth__tbankRedirect)}
          onClick={handleTbankRedirect}
        >
          Оформить подписку
        </button>
      </div>
    </div>
  );
}

TbankReAuth.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

TbankReAuth.defaultProps = {
  className: '',
};
