import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
// import { toJS } from 'mobx';

import styles from './ModeratedProgramMember.module.scss';
import Card from '../Card/Card';
import TopPageNotice from '../TopPageNotice/TopPageNotice';
import QRCodeScanner from '../QRCodeScanner';
import SearchProgramMemberForm from './SearchProgramMemberForm';
import ModeratedProgramMemberStore from '../../stores/moderatedProgramMember';

function ModeratedProgramMember({ title }) {
  const {
    recipient,
    programMember,
    isProgramMemberLoading,
    resetProgramMember,
    fetchProgramMemberOnSpotwayByScan,
    takeBraclet,
  } = ModeratedProgramMemberStore;

  const [notice, setNotice] = useState(null);

  const handleTakeBracletClick = () => {
    takeBraclet();
  };

  const handleResetPlayerClick = () => {
    resetProgramMember();
    const scrolled = document.getElementById('js-scroll-container');

    scrolled.scrollTop = 0;
  };

  const handleProgramMemberFound = (result) => {
    setNotice(result);
  };

  const handleProgramMemberNotFound = (result) => {
    setNotice(result);
  };

  const handleScanCode = async (link) => {
    const result = await fetchProgramMemberOnSpotwayByScan(link);
    if (!result) {
      setNotice('Участник не найден');
    }
  };

  return (
    <Card noShadow className={styles.ModeratedProgramMember}>
      <TopPageNotice notice={notice} onClose={() => setNotice(null)} />

      {isProgramMemberLoading && 'Загрузка...'}

      { recipient?.member_phone && (
        <div className={styles.ModeratedProgramMember__info}>
          <div className={styles.ModeratedProgramMember__head}>
            {recipient.pnr && (<span>Заказ: <strong>{recipient.pnr}</strong></span>)}
            {recipient.member_count && (<span>Кол-во браслетов: <strong>{recipient.member_count}</strong></span>)}
          </div>
          <ul className={styles.ModeratedProgramMember}>
            {programMember?.map((member) => (
              <div className={styles.ModeratedProgramMember__row}>
                <li key={member.member_id}>
                  <div className={styles.ModeratedProgramMember__wrapHead}>
                    <div>
                      {member?.notice}
                    </div>
                    <div>
                      {member?.error}
                    </div>
                  </div>
                  <div className={styles.ModeratedProgramMember__wrapMember}>
                    {/* <IconSvg icon={MemberIcon} className={cn({ [styles.ModeratedProgramMember__filled]: member?.filled })} /> */}
                    <div className={styles.ModeratedProgramMember__fio}>
                      <div>
                        {member?.member_fio}
                      </div>
                      <a
                        href={`tel:${member?.member_phone}`}
                      >
                        {member?.member_phone}
                      </a>
                    </div>
                  </div>
                </li>
              </div>
            ))}
          </ul>

          {!recipient?.check && (
            <button
              type="button"
              className="button button_fullwidth button_secondary"
              onClick={handleTakeBracletClick}
            >
              Выдать браслеты

            </button>
          )}

          <button
            type="button"
            className="button button_secondary"
            onClick={handleResetPlayerClick}
          >
            Выход
          </button>
        </div>
      )}

      { !recipient?.member_phone && (
        <div className={styles.ModeratedProgramMember__head}>
          <QRCodeScanner onScan={handleScanCode}>
            {
              ({ openScanner, isCameraAvailable }) => (
                <button
                  type="button"
                  disabled={!isCameraAvailable}
                  className="button button_fullwidth button_tertiary"
                  onClick={openScanner}
                >
                  Сканировать QR-код из заказа
                </button>
              )
            }
          </QRCodeScanner>

          <h1 className={styles.ModeratedProgramMember__title}>{title}</h1>

          <SearchProgramMemberForm
            onProgramMemberFound={handleProgramMemberFound}
            onProgramMemberNotFound={handleProgramMemberNotFound}
          />
        </div>
      )}
    </Card>
  );
}

ModeratedProgramMember.propTypes = {
  title: PropTypes.string,
};

ModeratedProgramMember.defaultProps = {
  title: 'Поиск Участника',
};

export default observer(ModeratedProgramMember);
