import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { headersWithToken } from '../../../../common/javascripts/fetchOptions';
import CardHeader from '../CardHeader';

import styles from './PartnerLogos.module.scss';

export default function PartnerLogos({ isSeesHeader }) {
  const [logos, setLogos] = useState([]);

  const fetchLogos = async () => {
    try {
      const response = await fetch('/logos', {
        method: 'GET',
        headers: headersWithToken(),
      });

      const result = await response.json();

      if (response.ok) {
        setLogos(result.logos);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchLogos();
  }, []);

  if (logos && logos.length === 0) return null;

  const generalLogos = logos.filter((logo) => logo.general);
  const otherLogos = logos.filter((logo) => !logo.general);

  return (
    <>
      {isSeesHeader && (
        <CardHeader className={styles.Logos__header}>
          <h2>Наши партнеры</h2>
        </CardHeader>
      )}
      <>
        <div className={styles.Logos__general}>
          {
            generalLogos.map((logo) => (
              <a
                key={logo.id}
                href={`https://${logo.url}`}
                className={styles.Logos__partner}
                target="__blank"
                rel="noreferrer"
              >
                <img alt="" className={styles.Logos__partnerImage2} src={logo.logo_url} />
              </a>
            ))
          }
        </div>
        <div className={styles.Logos__general}>
          {
            otherLogos.map((logo) => (
              <a
                key={logo.id}
                href={`https://${logo.url}`}
                className={styles.Logos__partner}
                target="__blank"
                rel="noopener noreferrer"
              >
                <img alt="" className={styles.Logos__partnerImage2} src={logo.logo_url} />
              </a>
            ))
          }
        </div>
        {/* <div className={styles.Logos}>
          {
            logos.map((logo) => (
              <a
                key={logo.id}
                href={`https://${logo.url}`}
                className={styles.Logos__item}
                target="__blank"
                rel="noreferrer"
              >
                <div
                  className={styles.Logos__itemImage}
                  style={{ backgroundImage: `url(${logo.logo_url})` }}
                />
              </a>
            ))
          }
        </div> */}
      </>
    </>
  );
}

PartnerLogos.propTypes = {
  isSeesHeader: PropTypes.bool,
};

PartnerLogos.defaultProps = {
  isSeesHeader: true,
};
